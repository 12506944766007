<script lang="ts" setup>
import { ArrowTopRightOnSquareIcon, ClipboardIcon, TicketIcon } from '@heroicons/vue/24/outline';
import { Button } from '@app/customer/Components';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import type { Types } from '@app/shared/types/generated-v2';
import { useI18n } from 'vue-i18n';
import { useClipboard } from '@vueuse/core';
import { ref } from 'vue';

const { t } = useI18n();

const RECENTLY_COPIED_TIMEOUT = 750;

type CouponViewModel = Types['App.Models.ViewModels.Customer.CouponViewModel'];

const buttonColor = useBrandColor();

const props = defineProps<{
  coupon: CouponViewModel;
}>();

const { copy } = useClipboard();

let recentlyCopiedTimeout: NodeJS.Timeout | null = null;
const recentlyCopied = ref(false);

function copyCouponCode(): void {
  if (recentlyCopiedTimeout !== null) {
    clearTimeout(recentlyCopiedTimeout);
  }

  recentlyCopied.value = true;

  copy(props.coupon.code);

  recentlyCopiedTimeout = setTimeout(() => {
    recentlyCopied.value = false;
  }, RECENTLY_COPIED_TIMEOUT);
}
</script>

<template>
  <div class="isolate flex flex-col w-full rounded-lg p-5 bg-slate-50 border border-slate-200 items-start overflow-hidden">
    <div class="relative">
      <div
        class="grid place-items-center size-11 rounded-full"
        :style="{
          backgroundColor: buttonColor.background,
          color: buttonColor.foreground,
        }"
      >
        <TicketIcon class="size-6" />
      </div>
      <div class="z-0 grid place-items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div class="absolute ring-1 ring-slate-200 rounded-full opacity-60 w-[calc(100%+(48px*2))] h-[calc(100%+(48px*2))]" />
        <div class="absolute ring-1 ring-slate-200 rounded-full opacity-50 w-[calc(100%+(48px*3))] h-[calc(100%+(48px*3))]" />
        <div class="absolute ring-1 ring-slate-200 rounded-full opacity-40 w-[calc(100%+(48px*4))] h-[calc(100%+(48px*4))]" />
        <div class="absolute ring-1 ring-slate-200 rounded-full opacity-30 w-[calc(100%+(48px*5))] h-[calc(100%+(48px*5))]" />
        <div class="absolute ring-1 ring-slate-200 rounded-full opacity-20 w-[calc(100%+(48px*6))] h-[calc(100%+(48px*6))]" />
      </div>
    </div>
    <div class="flex flex-col mt-4 z-10 text-sm gap-1">
      <span class="font-semibold text-lg">
        {{ t('customer.status:coupon:title', { amount: coupon.amount }) }}
      </span>
      <span class="text-slate-600 text-base">
        {{ t('customer.status:coupon:description') }}
      </span>
    </div>
    <div class="w-full flex flex-col sm:flex-row gap-3 sm:items-center mt-4">
      <div class="grow bg-white rounded-md border-2 border-dashed grid place-items-center min-h-9 sm:h-auto">
        <span class="tracking-wide text-center text-sm">
          {{ coupon.code }}
        </span>
      </div>
      <div class="shrink-0 relative">
        <Button
          class="w-full"
          :color="buttonColor"
          @click="copyCouponCode"
        >
          <ClipboardIcon class="mr-2 size-5" />
          <span>{{ t('customer.status:coupon:copy-code') }}</span>
        </Button>

        <div
          v-if="recentlyCopied"
          class="bg-gray-700 absolute top-0 -translate-y-[110%] left-1/2 -translate-x-1/2 rounded text-white text-base px-2 shadow"
        >
          {{ t('customer.status:coupon:copied') }}
        </div>
      </div>
      <div class="shrink-0">
        <a
          :href="coupon.autoApplyUrl ?? '#'"
          target="_blank"
        >
          <Button
            class="w-full"
          >
            <ArrowTopRightOnSquareIcon class="mr-2 size-5" />
            <span>{{ t('customer.status:coupon:visit-webshop') }}</span>
          </Button>
        </a>
      </div>
    </div>
  </div>
</template>
