<script lang="ts" setup>
import {
  Button,
  DescriptionItem,
  DescriptionList,
  Heading,
  HeadingSize,
  HeadingWeight,
  Link,
  Progress,
  ProgressMobile,
  ResourceItem,
  ResourceList,
  Section,
  SectionSpacing,
} from '@app/customer/Components';
import { AppLayout, AppLayoutWidth } from '@app/customer/Layouts';
import {
  Notes,
  OrderStatusAddShipment,
  OrderStatusAnswerList,
  OrderStatusCoupon,
  OrderStatusHeader,
  OrderStatusReturnItem,
  OrderStatusShipment,
  OrderStatusTransaction,
  ReturnOrderStateProgress,
} from '@app/customer/Partials';
import { App, type Types } from '@app/shared/types/generated-v2';
import { usePage } from '@inertiajs/vue3';
import { isEmpty } from 'lodash';
import { computed, provide } from 'vue';
import { PrinterIcon } from '@heroicons/vue/24/solid';
import { NoSymbolIcon } from '@heroicons/vue/24/outline';

type ShowOrderStatusViewModel = Types['App.Http.ViewModels.Customer.ShowOrderStatusViewModel'];
type FormLocaleViewModel = Types['App.Models.ViewModels.Customer.FormLocaleViewModel'];

const props = defineProps<ShowOrderStatusViewModel>();

const formLocale = computed<FormLocaleViewModel>(() => {
  return usePage().props.formLocale as FormLocaleViewModel;
});

const canCreateAdditionalShipments = computed(() => {
  const form = props.returnOrderIntent.form;
  if (form.multicollo === 0) {
    return false;
  }

  if (form.multicollo === -1) {
    return true;
  }

  if (props.shipments.length >= form.multicollo + 1) {
    return false;
  }

  return true;
});

function printReturnForm(): void {
  window.open(route('customer.intent.status.print-return-form', {
    returnOrderIntent: props.returnOrderIntent.id,
  }));
}

provide('attachmentFileSizeLimit', props.attachmentFileSizeLimit);
provide('attachmentFileTypes', props.attachmentFileTypes);
provide('stateOptions', props.stateOptions);
provide('returnOrderIntent', props.returnOrderIntent);
</script>

<template>
  <AppLayout :width="AppLayoutWidth.Large">
    <div class="w-full space-y-16">
      <Section :spacing="SectionSpacing.Large">
        <div
          v-if="returnOrderState === App.Enums.ReturnOrder.ReturnOrderState.REQUEST_REJECTED"
          class="flex size-14 items-center justify-center rounded-full bg-red-100"
        >
          <span class="color size-7 text-red-600">
            <NoSymbolIcon />
          </span>
        </div>

        <OrderStatusHeader
          :return-order-state="returnOrderState"
          :default-return-instruction="defaultReturnInstruction"
          :return-instruction="returnInstruction"
          :return-number="returnNumber"
          :sales-order-number="salesOrderNumber"
          :order-created-at="orderCreatedAt"
          :form-locale="formLocale "
        />

        <ReturnOrderStateProgress
          v-if="useNewReturnOrderStates"
          :return-order-state="state"
          :expects-shipments="expectsShipments"
        />

        <template v-if="!isEmpty(statuses) && !useNewReturnOrderStates">
          <div class="hidden sm:block">
            <Progress :blocks="statuses" />
          </div>

          <div class="sm:hidden">
            <ProgressMobile :blocks="statuses" />
          </div>
        </template>
      </Section>

      <div
        v-if="coupons && coupons.length > 0"
        class="flex flex-col gap-6 w-full"
      >
        <OrderStatusCoupon
          v-for="coupon in coupons"
          :key="coupon.id"
          :coupon="coupon"
        />
      </div>

      <div>
        <Button
          v-if="isPrintReturnFormEnabled"
          @click="printReturnForm"
        >
          <PrinterIcon class="mr-2 size-5" />
          <span>{{ $t('customer.status:print-return') }}</span>
        </Button>
      </div>

      <Section v-if="!isEmpty(shipments) || returnOrderState === 'request-approved'">
        <Heading
          :size="HeadingSize.Large"
          :weight="HeadingWeight.Bold"
        >
          {{ $t('customer.status:shipment:title') }}
        </Heading>

        <div
          v-if="returnOrderState === 'request-approved'"
          class="mt-6"
        >
          <OrderStatusAddShipment :return-order-intent="returnOrderIntent" />
        </div>

        <ul
          v-if="!isEmpty(shipments)"
          class="space-y-8"
        >
          <li
            v-for="shipment in shipments"
            :key="shipment.id"
          >
            <OrderStatusShipment
              :return-order-intent="returnOrderIntent"
              :address-data="addressData"
              :country-name="countryName"
              :shipment="shipment"
              :visible-product-details="returnOrderIntent.form.visibleProductDetails"
              :answers="answers"
              :reply-to-address="formLocale.replyToAddress"
            />
          </li>
        </ul>

        <div
          v-if="canCreateAdditionalShipments"
          class="mt-4 flex w-full justify-end"
        >
          <Link :href="$route('customer.intent.shipping-method', returnOrderIntent)">
            <Button>
              {{ $t('customer.status:shipment:additional') }}
            </Button>
          </Link>
        </div>
      </Section>

      <Section v-if="canSplitOrder">
        <Heading
          :size="HeadingSize.Large"
          :weight="HeadingWeight.Bold"
        >
          {{ $t('customer.status:your-order') }}
        </Heading>
        <div class="flex items-center justify-between border-slate-200 p-4 text-sm shadow-sm sm:rounded-lg sm:border">
          <DescriptionList>
            <DescriptionItem
              :term="formLocale.orderFieldLabel"
            >
              {{ salesOrderNumber }}
            </DescriptionItem>
          </DescriptionList>
          <div>
            <Link :href="createAnotherReturnForOrderRoute">
              <Button>
                {{ $t('customer.status:split-order:create-additional-return') }}
              </Button>
            </Link>
          </div>
        </div>
      </Section>
      <Section v-if="! isEmpty(returnOrderIntent.paymentTransactions)">
        <Heading
          :size="HeadingSize.Large"
          :weight="HeadingWeight.Bold"
        >
          {{ $t('customer.status:payment-transaction:transactions') }}
        </Heading>

        <ResourceList>
          <ResourceItem
            v-for="transaction in returnOrderIntent.paymentTransactions"
            :key="transaction.id"
          >
            <OrderStatusTransaction v-bind="transaction" />
          </ResourceItem>
        </ResourceList>
      </Section>

      <Section v-if="isEmpty(shipments)">
        <ResourceList>
          <ResourceItem
            v-for="(returnOrderItem, key) in returnOrderItems"
            :key="key"
          >
            <OrderStatusReturnItem
              :return-order-intent="returnOrderIntent"
              :visible-product-details="returnOrderIntent.form.visibleProductDetails"
              :return-order-item="returnOrderItem"
            />
          </ResourceItem>
          <ResourceItem v-if="!isEmpty(answers)">
            <DescriptionList>
              <DescriptionItem>
                <OrderStatusAnswerList :answers="answers" />
              </DescriptionItem>
            </DescriptionList>
          </ResourceItem>
        </ResourceList>
      </Section>

      <Section v-if="returnOrderIntent.form.areCustomerNotesAllowed || notes.length > 0">
        <Heading
          :size="HeadingSize.Large"
          :weight="HeadingWeight.Bold"
        >
          {{ $t('customer.status:notes:title') }}
        </Heading>

        <Notes
          :readonly="! returnOrderIntent.form.areCustomerNotesAllowed"
          :notes="notes"
        />
      </Section>
    </div>
  </AppLayout>
</template>
