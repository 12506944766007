<script lang="ts" setup>
import {
  Banner,
  BannerVariant,
  Button,
  ButtonGroup,
  Form,
  FormLayout,
  Select,
  TextInput,
} from '@app/customer/Components';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import * as Types from '@app/panel/types/generated';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import { inject } from 'vue';

type ShipmentViewModel = Types.App.Models.ViewModels.Customer.ShipmentViewModel;
type ReturnOrderIntentViewModel = Types.App.Models.ViewModels.Customer.ReturnOrderIntentViewModel;
type SelectOptionViewModel = Types.App.Models.ViewModels.SelectOptionViewModel;
type AddressDataViewModel = Types.App.Models.ViewModels.Customer.AddressDataViewModel;

const props = defineProps<{
  returnOrderIntent: ReturnOrderIntentViewModel;
  shipment: ShipmentViewModel;
  addressData: AddressDataViewModel;
  countryName: string;
}>();

const brandColor = useBrandColor();

const form = useForm({
  name: props.addressData.name,
  phone_number: props.addressData.phone_number,
  email: props.addressData.email,
  street: props.addressData.street,
  house_number: props.addressData.house_number,
  suffix: props.addressData.suffix,
  postcode: props.addressData.postcode,
  city: props.addressData.city,
  state: props.addressData.state,
});

const stateOptions = inject<Array<SelectOptionViewModel>>('stateOptions')!;

function retryShipment(): void {
  form.put(route('customer.intent.shipment.retry', {
    returnOrderIntent: props.returnOrderIntent,
    shipment: props.shipment,
  }), {
    preserveScroll: true,
  });
}
</script>

<template>
  <div class="space-y-4 p-4">
    <div>
      <Banner
        :variant="BannerVariant.Critical"
        :title="$t('customer.status:shipment-failed', {
          shippingMethod: shipment.shippingMethod.name
        })"
      />
    </div>
    <div class="rounded-md bg-slate-50 p-4">
      <Form>
        <FormLayout>
          <FormLayout :columns-sm="3">
            <FormLayout>
              <TextInput
                v-model="form.name"
                :label="$t('customer.status:shipment:name')"
                :error="form.errors.name"
              />
            </FormLayout>
            <FormLayout>
              <TextInput
                v-model="form.phone_number"
                :label="$t('customer.status:shipment:phone-number')"
                :error="form.errors.phone_number"
              />
            </FormLayout>
            <FormLayout>
              <TextInput
                v-model="form.email"
                :label="$t('customer.status:shipment:email-address')"
                :error="form.errors.email"
              />
            </FormLayout>
          </FormLayout>
          <FormLayout :columns-sm="2">
            <FormLayout>
              <TextInput
                v-model="form.street"
                :label="$t('customer.status:shipment:street')"
                :error="form.errors.street"
              />
            </FormLayout>
            <FormLayout :columns-sm="2">
              <FormLayout>
                <TextInput
                  v-model="form.house_number"
                  :label="$t('customer.status:shipment:house-number')"
                  :error="form.errors.house_number"
                />
              </FormLayout>
              <FormLayout>
                <TextInput
                  v-model="form.suffix"
                  :label="$t('customer.status:shipment:suffix')"
                  :error="form.errors.suffix"
                />
              </FormLayout>
            </FormLayout>
          </FormLayout>
          <FormLayout :columns-sm="2">
            <FormLayout :columns-sm="2">
              <FormLayout>
                <TextInput
                  v-model="form.postcode"
                  :label="$t('customer.status:shipment:postcode')"
                  :error="form.errors.postcode"
                />
              </FormLayout>
              <FormLayout>
                <TextInput
                  v-model="form.city"
                  :label="$t('customer.status:shipment:city')"
                  :error="form.errors.city"
                />
              </FormLayout>
            </FormLayout>
            <FormLayout>
              <TextInput
                :model-value="countryName"
                :label="$t('customer.status:shipment:country')"
                disabled
              />
            </FormLayout>
          </FormLayout>

          <FormLayout
            v-if="addressData.state"
            :columns-sm="2"
          >
            <FormLayout>
              <Select
                v-model="form.state"
                :label="$t('customer.address:address:state:label')"
                :options="stateOptions"
                :error="form.errors.state"
              />
            </FormLayout>
          </FormLayout>
          <FormLayout>
            <ButtonGroup full-width>
              <Button
                :color="brandColor"
                :loading="form.processing"
                @click="retryShipment"
              >
                <template #icon>
                  <ArrowPathIcon />
                </template>

                <span>{{ $t('customer.status:shipment:retry-shipment') }}</span>
              </Button>
            </ButtonGroup>
          </FormLayout>
        </FormLayout>
      </Form>
    </div>
  </div>
</template>
